import React, { useEffect, useState } from "react";
import Styles from "./filterScreen.module.scss";
import AppBar from "shared/appBar/appBar";
import { useLocation } from "react-router-dom";
import Button from "shared/button/button";
import { LARGE } from "shared/buttonSize";
import {
  primaryButtonHoveredStyle,
  primaryButtonStyle,
} from "shared/buttonStyles";

const FilterScreen = () => {
  const { state } = useLocation();
  const [selectedMenu, setSelectedMenu] = useState<number>(0);
  const clearLabel = <div className={Styles.actionAppBar}>Clear All</div>;
  useEffect(() => {
    Object.keys(state.filters).map((item, index) => {
      if (item === state.activeFilter)
        setSelectedMenu(index);
    })
  }, []);
  return (
    <>
      <AppBar title={"Filters"} action={clearLabel} />
      <div className={"d-flex mt-3"}>
        <div className={`${Styles.menu}`}>
          {Object.keys(state.filters).map((item, index) => {
            return (
              <div
                className={`${Styles.menuItem} ${
                  index === selectedMenu ? Styles.active : ""
                }`}
                key={index}
                onClick={() => {
                  setSelectedMenu(index);
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
        <div className={`${Styles.data}`}>
          {state.filters[Object.keys(state.filters)[selectedMenu]].map((item, index) => {
            return (
              <div className={`${Styles.dataItem} d-flex pb-4`} key={index}>
                <div>
                  <input
                    className={"dataItem-checkbox"}
                    type="checkbox"
                    name={item}
                    id={item}
                    value={item}
                  />
                </div>
                <div>
                  <label htmlFor={item}>{item}</label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Styles.bottomButton}>
        <Button
          isLoading={false}
          size={LARGE}
          disabled={true}
          style={primaryButtonStyle}
          hoveredStyle={primaryButtonHoveredStyle}
        >
          Apply Filter
        </Button>
      </div>
    </>
  );
};

export default FilterScreen;
