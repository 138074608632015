import React, { useEffect, useState } from "react";
import AppBar from "shared/appBar/appBar";
import Styles from "./ehr.module.scss";
import SeachInput from "shared/searchInput/searchInput";
import { BottomNavigation } from "shared/bottomNavigation/bottomNavigation";
import { EHR } from "shared/constants";
import BottomSheet from "shared/bottomSheet/bottomSheet";
import { EhrAPI } from "apis/ehrAPI";
import EhrFileCard from "shared/ehrFileCard/ehrFileCard";
import Loading from "shared/loading/loading";
import Symptoms from "shared/symptoms/symptoms";
import CardView from "shared/cardView/cardView";
import { Link, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";

export default function Ehr() {
  const navigate = useNavigate();
  const [tabState, setTabState] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [ehrData, setEhrData] = useState<any>({
    patientEHR: [],
    familyEHR: {},
  });
  const [ehrDataProp, setEhrDataProp] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showReportFiles, setShowReportFiles] = useState(false);
  const [reportFiles, setReportFiles] = useState([]);

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }

  function onToggle() {
    setIsOpen(true);
  }

  function getEhrData() {
    setIsLoading(true);
    EhrAPI.getCompleteEhr()
      .then((response) => {
        if (response.success === true) {
          // Process Patient's Reports
          let patientFiles: any = [];
          response.data.patientEHR.forEach((ehr) => {
            patientFiles.push(
              ehr.report.map((reportFile) => {
                return {
                  fileName: reportFile.originalName,
                  fileLink: reportFile.report,
                };
              })
            );
          });
          // Process Family Member's Rreports
          let familyEhrHM = new Map();
          response.data.familyEHR.forEach((ehr: any) => {
            const patientId = ehr.patientId._id;
            let familyMemberEhr: any = [];
            if (familyEhrHM.has(patientId)) {
              familyMemberEhr = familyEhrHM.get(patientId);
            }
            familyMemberEhr = [
              ...familyMemberEhr,
              ehr.report.map((reportObj) => {
                return {
                  fileName: reportObj.originalName,
                  fileLink: reportObj.report,
                };
              }),
            ];

            familyEhrHM.set(patientId, familyMemberEhr);
          });
          setEhrData({ patientEHR: patientFiles, familyEHR: familyEhrHM });
          setEhrDataProp(patientFiles);
          setIsLoading(false);

          // Set Family Members Info to show on Family Reports Section
          let familyMembersInfo: any = [];
          response.data.familyEHR.forEach((report: any) => {
            if (
              !familyMembersInfo.some(
                (item: any) => item.id === report.patientId._id
              )
            ) {
              familyMembersInfo.push({
                id: report.patientId._id,
                image: report.patientId.image,
                title: report.patientId.name,
              });
            } else {
              return;
            }
          });
          setFamilyMembers(familyMembersInfo);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function selectTab(tabId) {
    setTabState(tabId);
    //  For Family Report tabId == 1
    if (tabId === 1) {
      // set Inital Report display to empty
      setEhrDataProp([]);
    } else setEhrDataProp(ehrData.patientEHR);
  }

  function updateReportsForFamilyMemberSelected(memberId: string) {
    const familyMemberEhr = ehrData.familyEHR.get(memberId);
    setEhrDataProp(familyMemberEhr);
  }

  // Show Report Files View
  function showReportFilesOnMemberSelect(reports: any) {
    setShowReportFiles(true);
    const reportData = reports.map((report, index) => {
      return {
        id: index,
        title: report.fileName,
        image: report.fileLink,
      };
    });
    setReportFiles(reportData);
  }

  useEffect(() => {
    selectTab(0);
    // Fetch Data on page Load only.
    getEhrData();
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      {showReportFiles === true ? (
        <>
          <div
            className={`${Styles.hideReportActionBackButton}`}
            onClick={() => setShowReportFiles(false)}
          >
            <span className="material-symbols-outlined">arrow_back</span>
          </div>
          <AppBar title={"Reports"} />
          {reportFiles &&
            reportFiles.map((files: any, index) => {
              return (
                <div className={`${Styles.reportFile} d-flex`} key={index}>
                  <div className={`${Styles.reportFileImage}`}>
                    <img src={files.image} alt="Report File" />
                  </div>
                  <div className={`${Styles.reportFileText}`}>
                    {files.title}
                    <div className={`${Styles.actionButtonsContainer}`}>
                      <Link to={files.image} target="_blank" download={true}>
                        <span
                          className={`${Styles.reportActionButtons} material-symbols-outlined`}
                        >
                          download
                        </span>
                      </Link>
                      <RWebShare
                        data={{
                          text: `${files.title}`,
                          url: `${files.image}`,
                          title: "Share EHR Report",
                        }}
                      >
                        <span
                          className={`${Styles.reportActionButtons} material-symbols-outlined`}
                        >
                          share
                        </span>
                      </RWebShare>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        <>
          {/* <TopNavigation /> */}
          <AppBar title={"EHR"} />

          {/* Tabs selection */}
          <div className={`${Styles.tabs} d-flex`}>
            <div
              className={`${Styles.tabItem} ${
                tabState === 0 ? Styles.active : ""
              }`}
              onClick={() => selectTab(0)}
            >
              My Report
            </div>
            <div
              className={`${Styles.tabItem} ${
                tabState === 1 ? Styles.active : ""
              }`}
              onClick={() => selectTab(1)}
            >
              Family Reports
            </div>
          </div>
          <div className="mt-3"></div>

          {/* Search Bar */}
          <SeachInput />

          {/* Display Profiles if Family Reports is Selcted in Symptoms Style UI */}
          {familyMembers && tabState === 1 && (
            <div className={`${Styles.symptomsContainer} mt-3`}>
              <Symptoms
                symptoms={familyMembers}
                multiSelection={false}
                style={`d-flex flex-nowrap`}
                sendItemIdToParent={updateReportsForFamilyMemberSelected}
              />
            </div>
          )}

          {/* Display EHR Files */}
          <section className={`${Styles.filesContainer} mt-3`}>
            {isLoading ? (
              <Loading />
            ) : (
              <EhrFileCard
                ehrData={ehrDataProp}
                sendReportsArrayToParent={showReportFilesOnMemberSelect}
              />
            )}
          </section>

          <button className={Styles.floatingButton} onClick={onToggle}>
            +
          </button>
        </>
      )}
      {/* Bottom Navigtion */}
      <BottomNavigation navigationSelection={EHR} />
      <BottomSheet isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </div>
  );
}
