import { validateResponse } from "utils/apiValidations";
import {
  endpoint_getAllDoctors,
  endpoint_getDoctorSlots,
  endpoint_getPopularDoctors,
} from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const DoctorsAPI = {
  // Get All Doctors
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: endpoint_getAllDoctors,
      method: "POST",
      headers: {
        Authorization: process.env.REACT_APP_AUTH_TOKEN,
      },
      signal: cancel
        ? cancelApiObject[this.getAll.name].handelRequestCancellation().signal
        : undefined,
    });
    return validateResponse(response);
  },

  // Get Slots Based on Doctor's ID and Date
  getSlots: async function (
    params = { doctorId: "", date: "" },
    cancel = false
  ) {
    const response = await api.request({
      url: endpoint_getDoctorSlots,
      method: "GET",
      params: {
        doctorId: params.doctorId,
        date: params.date,
      },
      signal: cancel
        ? cancelApiObject[this.getSlots.name].handelRequestCancellation().signal
        : undefined,
    });
    return response;
  },

  // Get Popular Doctor's List
  getPopularDoctor: async function (cancel = false) {
    const response = await api.request({
      url: endpoint_getPopularDoctors,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handelRequestCancellation().signal
        : undefined,
    });
    return response;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(DoctorsAPI);
