import { validateResponse } from "utils/apiValidations";
import { ehr_getCompleteData, endpoint_getDoctorSlots } from "./apiEndpoints";
import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";
import { getAccessTokenFromLocal } from "utils/utils";

export const EhrAPI = {
  // Get All Doctors
  getCompleteEhr: async function (cancel = false) {
    const patientId = JSON.parse(localStorage.getItem("profileData")!).id;
    const response = await api.request({
      url: ehr_getCompleteData + patientId,
      method: "GET",
      headers: {
        Authorization: getAccessTokenFromLocal(),
      },
      signal: cancel
        ? cancelApiObject[this.getCompleteEhr.name].handelRequestCancellation()
            .signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },

  // Get Slots Based on Doctor's ID and Date
  getSlots: async function (
    params = { doctorId: "", date: "" },
    cancel = false
  ) {
    const response = await api.request({
      url: endpoint_getDoctorSlots,
      method: "GET",
      params: {
        doctorId: params.doctorId,
        date: params.date,
      },
      signal: cancel
        ? cancelApiObject[this.getSlots.name].handelRequestCancellation().signal
        : undefined,
    });
    const result = validateResponse(response);
    return result;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(EhrAPI);
