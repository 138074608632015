import React, { useState } from "react";
import Styles from "./symptoms.module.scss";
import { DiseaseDefaultImage } from "shared/constants";

export default function Symptoms({
  symptoms,
  style,
  multiSelection,
  sendItemIdToParent,
}: any) {
  const [selectItem, setSelectedItem] = useState<number[]>([]);
  return (
    <div className={style}>
      {symptoms &&
        symptoms.map((symptom: any, index: number) => {
          return (
            <div
              key={symptom.id ?? index}
              className={`${Styles.symptomContainer}`}
              onClick={(e) => {
                if (selectItem.includes(index)) {
                  multiSelection
                    ? setSelectedItem(selectItem.filter((val) => val !== index))
                    : setSelectedItem([]);
                } else {
                  multiSelection
                    ? setSelectedItem([...selectItem, index])
                    : setSelectedItem([index]);
                }
                sendItemIdToParent(symptom.id);
              }}
            >
              <div
                className={`${
                  selectItem.includes(index) ? Styles.active : ""
                } ${Styles.symptomImage}`}
              >
                <img
                  src={symptom.image ?? DiseaseDefaultImage}
                  alt={symptom.title}
                />
              </div>
              <div className={`${Styles.symptomTitle}`}>{symptom.title}</div>
            </div>
          );
        })}
    </div>
  );
}
