import React, { useState } from "react";
import Styles from "./lobbyScreen.module.scss";
import AppBar from "shared/appBar/appBar";
import Button from "shared/button/button";
import { primaryButtonStyle } from "shared/buttonStyles";
import { LARGE } from "shared/buttonSize";
import { NORMAL } from "shared/constants";

const LobbyScreen = () => {
  const [isActive, setIsActive] = useState<string[]>([]);
  function toggleActiveButton(type: string) {
    if (isActive.includes(type)) {
      const list = isActive.filter((val) => val !== type);
      setIsActive(list);
    } else {
      setIsActive([...isActive, type]);
    }
  }
  return (
    <div className={""}>
      <AppBar title={"Lobby Screen"} />
      <div
        className={`${Styles.avButtonsContainer} d-flex justify-content-center`}
      >
        <div
          className={`${Styles.avButton}`}
          onClick={() => {
            toggleActiveButton("audio");
          }}
        >
          <div
            className={`${Styles.avButtonImage} ${
              isActive.includes("audio") ? Styles.active : ""
            }`}
          >
            {isActive.includes("audio") ? (
              <span
                className={`${Styles.materialIcon} material-symbols-outlined`}
              >
                mic
              </span>
            ) : (
              <span
                className={`${Styles.materialIcon} material-symbols-outlined`}
              >
                mic_off
              </span>
            )}
          </div>
          <div className={Styles.avButtonTitle}>
            {isActive.includes("audio") ? "Audio Active" : "Audio InActive"}
          </div>
        </div>
        <div
          className={`${Styles.avButton}`}
          onClick={() => {
            toggleActiveButton("video");
          }}
        >
          <div
            className={`${Styles.avButtonImage} ${
              isActive.includes("video") ? Styles.active : ""
            }`}
          >
            {isActive.includes("video") ? (
              <span
                className={`${Styles.materialIcon} material-symbols-outlined`}
              >
                videocam
              </span>
            ) : (
              <span
                className={`${Styles.materialIcon} material-symbols-outlined`}
              >
                videocam_off
              </span>
            )}
          </div>
          <div className={Styles.avButtonTitle}>
            {isActive.includes("video") ? "Video Active" : "Video InActive"}
          </div>
        </div>
      </div>
      <div className={`${Styles.startButton}`}>
        <Button style={primaryButtonStyle} size={LARGE}>
          START
        </Button>
      </div>
    </div>
  );
};

export default LobbyScreen;
