import React, { useEffect, useState } from "react";
import Styles from "./topNavigation.module.scss";
import { Link } from "react-router-dom";
import { IntProfileData } from "apis/interface";
import { UserDefaultImage_Male } from "shared/constants";

const TopNavigation = () => {
  const [userProfile, setUserProfile] = useState<IntProfileData>();
  const cartCount = 99;
  useEffect(() => {
    const profileInfo: IntProfileData = localStorage.getItem("profileData")
      ? JSON.parse(localStorage.getItem("profileData")!)
      : {
          id: "",
          name: "Guest",
          age: 0,
          mobile: "",
          gender: "",
          image: UserDefaultImage_Male,
        };
    profileInfo.image = profileInfo.image ?? UserDefaultImage_Male;
    setUserProfile(profileInfo);
  }, []);
  return (
    <div className={"mb-5"}>
      {/* Top Container Profile, Search, Notifications & Cart */}
      <div className={`${Styles.topContainer} d-flex mb-4 align-items-center`}>
        <div>
          <Link to={"/profile"}>
            <div className={Styles.profileIcon}>
              <img src={userProfile?.image} alt="User Profile" />
            </div>
          </Link>
        </div>
        <div className={Styles.searchBar}>
          <input type="search" name="search" id="search" placeholder="Search" />
        </div>
        <div className={Styles.icons}>
          &nbsp;&nbsp;
          <Link to="/notifications">
            <span className="material-symbols-outlined">notifications</span>
          </Link>
          &nbsp;&nbsp;
          <Link to="/cart">
            <span className="material-symbols-outlined">shopping_cart</span>
            <div className={Styles.cartBubble}>{cartCount}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
