import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "shared/appBar/appBar";
import Styles from "./chatWidget.module.scss";
import InputField from "shared/inputField/inputField";

const ChatWidget = () => {
  const { state } = useLocation();
  useEffect(() => {
    // console.log(state);
  });
  return (
    <>
      <AppBar title="Chat Details" />
      <div className={Styles.chatDisplay}>
        <div className={`${Styles.inputContainer} p-3 d-flex`}>
          <input type={"text"} placeholder={"Type a message..."} />
          <div className={`${Styles.sendButton} ${Styles.active1}`}>
            <span className="material-symbols-outlined">send</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatWidget;
